import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getHeadersForRequest,
  isFirstOrderType,
  numberWithCommas,
} from "../utils/functions";
import axios from "axios";
import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { Loading } from "../components/Loading";
import moment from "moment";
import jsPDF from "jspdf";

import QRCode from "qrcode";

import "jspdf-autotable";
import QRCodeComponent from "../components/QRCodeComponent";
import Nav from "../components/navbar";
import Cookies from "js-cookie";

const logo = require("../resources/images/logo.jpg");
const logo1 = require("../resources/images/logo1.png");
const logo2 = require("../resources/images/koas-logo.png");

const OrderDetailsScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [genetatingPDF, setGeneratingPDF] = useState<boolean>(false);

  const { orderId } = useParams<{ orderId: string }>();

  useEffect(() => {
    setIsLoading(true);
    getOrderDetails();
    console.log(orderId);
  }, []);

  const getOrderDetails = async () => {
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      orderid: orderId,
    };
    const headers = { ...oldHeaders, ...newHeaders };

    try {
      const response = await axios.get<[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/orderdetails`,
        { headers }
      );

      setOrders(response.data);
      setIsLoading(false);
      console.log("data " + JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const generatePDF = async (data: any) => {
    setGeneratingPDF(true);
    const doc = new jsPDF();

    console.log("data: " + JSON.stringify(data[0]));

    // Load an image and add it to the PDF
    const img = new Image();
    img.src = logo1;
    img.onload = async () => {
      // Get the dimensions of the image and the PDF
      const imgWidth = 30; // Adjust as needed
      const imgHeight = 30; // Adjust as needed
      const pageWidth = doc.internal.pageSize.getWidth();

      // Position the image in the top right corner
      const x = pageWidth - imgWidth - 15; // 10 units from the right edge
      const y = 20; // 10 units from the top edge

      doc.addImage(img, "JPEG", x, y, imgWidth, imgHeight);

      // Add dynamic content
      doc.text(`INVOICE ${data[0].transactionNumber}`, 15, 30);
      doc.text(`Delivery Note# ${data[0].deliveryNoteNumber}`, 15, 40);
      doc.text(`${data[0].name}`, 15, 61);
      data[0].tin && doc.text(`${data[0].tin}`, 15, 67);
      Cookies.get("businessName") === "Keinth's Food" &&
        doc.text("105213395", pageWidth - 43, 58);
      doc.text(
        `${moment(data[0].transactionDate).format("DD MMM YYYY")}`,
        pageWidth - 45,
        67
      );

      // Table data
      const tableColumn = ["Quantity", "Description", "Amount"];
      const tableRows: any[] = [];

      data.forEach(
        (item: {
          packetNumber: number;
          flavour: string;
          size: string;
          product: string;
          price: number;
        }) => {
          const flavour = item.flavour === null ? "" : item.flavour;

          const tableRow = [
            item.packetNumber,
            item.product + " " + flavour + " " + item.size + " gms",
            numberWithCommas(item.packetNumber * item.price),
          ];
          tableRows.push(tableRow);
        }
      );

      let finalY = imgHeight + 30;

      console.log("doc " + JSON.stringify(tableRows));

      (doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: imgHeight + 40, // Start table below the image
        didDrawPage: (dataArg: any) => {
          finalY = dataArg.cursor.y; // Get Y position of the end of the table
        },
        headStyles: {
          fontSize: 15,
          2: { halign: "right" },
        },
        bodyStyles: {
          fontSize: 15,
        },
        columnStyles: {
          2: { halign: "right" },
        },
      });

      // Add total
      const total = `Total: ${data[0].totalAmount}`;
      const totalX = pageWidth - doc.getTextWidth(total) - 30; // Right-align the total
      doc.text(
        `TOTAL: ${numberWithCommas(data[0].totalAmount)}`,
        totalX,
        finalY + 10
      );

      // Generate QR code and add to PDF
      const qrCodeValue =
        orders[0].QRLink +
        orders[0].traCode +
        orders[0].traReceiptNumber +
        "_" +
        orders[0].traTime;

      try {
        const qrCodeDataUrl = await QRCode.toDataURL(qrCodeValue);
        doc.addImage(qrCodeDataUrl, "PNG", 10, finalY + 20, 50, 50); // Adjust position and size as needed
        doc.text(
          `${data[0].traCode}${data[0].traReceiptNumber}`,
          70,
          finalY + 30
        );
        doc.text(
          `${data[0].traTime.slice(0, 2)}:${data[0].traTime.slice(
            2,
            4
          )}:${data[0].traTime.slice(4)}`,
          70,
          finalY + 40
        );
      } catch (error) {
        console.error("QR Code generation error:", error);
      }

      // Generate PDF and get URL
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
      onOpen();
      setGeneratingPDF(false);
    };
  };

  const generatePDFGeneral = async (data: any) => {
    setGeneratingPDF(true);
    const doc = new jsPDF();

    console.log("data: " + JSON.stringify(data[0]));

    // Load an image and add it to the PDF
    const img = new Image();
    img.src = logo2;
    img.onload = async () => {
      // Get the dimensions of the image and the PDF
      const imgWidth = 27; // Adjust as needed
      const imgHeight = 30; // Adjust as needed
      const pageWidth = doc.internal.pageSize.getWidth();

      // Position the image in the top right corner
      const x = pageWidth - imgWidth - 15; // 10 units from the right edge
      const y = 20; // 10 units from the top edge

      doc.addImage(img, "JPEG", x, y, imgWidth, imgHeight);

      // Add dynamic content
      doc.text(`INVOICE ${data[0].transactionNumber}`, 15, 30);
      data[0].deliveryNoteNumber &&
        doc.text(`Delivery Note# ${data[0].deliveryNoteNumber}`, 15, 40);
      doc.text(`${data[0].name}`, 15, 61);
      doc.text(`${data[0].tin}`, 15, 67);
      doc.text(`${data[0].client_tin}`, pageWidth - 43, 58);
      doc.text(
        `${moment(data[0].transactionDate).format("DD MMM YYYY")}`,
        pageWidth - 45,
        67
      );

      // Table data
      const tableColumn = ["Quantity", "Description", "Amount"];
      const tableRows: any[] = [];

      data.forEach(
        (item: { packetNumber: number; product: string; price: number }) => {
          const tableRow = [
            item.packetNumber,
            item.product,
            numberWithCommas(item.packetNumber * item.price),
          ];
          tableRows.push(tableRow);
        }
      );

      let finalY = imgHeight + 30;

      console.log("doc " + JSON.stringify(tableRows));

      (doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: imgHeight + 40, // Start table below the image
        didDrawPage: (dataArg: any) => {
          finalY = dataArg.cursor.y; // Get Y position of the end of the table
        },
        headStyles: {
          fontSize: 15,
          2: { halign: "right" },
        },
        bodyStyles: {
          fontSize: 15,
        },
        columnStyles: {
          2: { halign: "right" },
        },
      });

      // Add total
      const total = `Total: ${data[0].totalAmount}`;
      const totalX = pageWidth - doc.getTextWidth(total) - 30; // Right-align the total
      doc.text(
        `TOTAL: ${numberWithCommas(data[0].totalAmount)}`,
        totalX,
        finalY + 10
      );

      // Generate QR code and add to PDF
      if (orders[0].invoice_verification_code) {
        const qrCodeValue =
          orders[0].QRLink +
          orders[0].invoice_verification_code +
          "_" +
          orders[0].invoice_time.split(":")[0] +
          orders[0].invoice_time.split(":")[1] +
          orders[0].invoice_time.split(":")[2];

        try {
          const qrCodeDataUrl = await QRCode.toDataURL(qrCodeValue);
          doc.addImage(qrCodeDataUrl, "PNG", 10, finalY + 20, 50, 50); // Adjust position and size as needed
          doc.text(`${orders[0].invoice_verification_code}`, 70, finalY + 30);
          doc.text(`${orders[0].invoice_time}`, 70, finalY + 40);
        } catch (error) {
          console.error("QR Code generation error:", error);
        }
      }

      // Generate PDF and get URL
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
      onOpen();
      setGeneratingPDF(false);
    };
  };

  const handleDownload = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${orders[0].transactionNumber} - ${orders[0].name}.pdf`;
      link.click();
    }
  };

  return (
    <Stack>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>PDF Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {pdfUrl && (
              <iframe
                ref={iframeRef}
                src={pdfUrl}
                width="100%"
                height="400px"
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleDownload}>
              Download PDF
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav />
        </Stack>
        <Stack>
          <Stack px={1}>
            {isLoading ? (
              <Center h={600}>
                <Loading />
              </Center>
            ) : (
              <Stack p={1}>
                {orders.length > 0 && (
                  <Stack borderWidth={2} borderRadius={5}>
                    <Stack
                      alignItems={"center"}
                      pt={2}
                      position={"absolute"}
                      right={0}
                      left={0}
                    >
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          if (Cookies.get("businessName") === "Keinth's Food") {
                            generatePDF(orders);
                          } else {
                            generatePDFGeneral(orders);
                          }
                        }}
                        isLoading={genetatingPDF}
                      >
                        Preview PDF
                      </Button>
                    </Stack>

                    <HStack
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                      p={1}
                      pl={3}
                    >
                      <Stack textAlign={"left"}>
                        <Text fontSize="2xl" fontFamily="">
                          INVOICE {orders[0].transactionNumber}
                        </Text>
                        <Text>
                          Delivery Note #{orders[0].deliveryNoteNumber}
                        </Text>
                        <br />
                        <br />
                        <Text fontSize="lg" fontFamily="" mb={-2}>
                          Customer
                        </Text>
                        <Text fontSize="lg" fontFamily="">
                          {orders[0].name ? orders[0].name : "Cash"}
                        </Text>
                        <Text fontSize="lg" fontFamily="">
                          {orders[0].tin}
                        </Text>
                      </Stack>
                      <Stack>
                        <img
                          src={
                            Cookies.get("businessName") === "Keinth's Food"
                              ? logo
                              : logo2
                          }
                          style={{ height: 200, backgroundColor: "white" }}
                        />
                        <Text
                          mb={-2}
                          fontSize="lg"
                          fontFamily=""
                          textAlign={"right"}
                        >
                          Invoice Date
                        </Text>
                        <Text fontSize="lg" fontFamily="" textAlign={"right"}>
                          {moment(orders[0].invoiceDate).format("DD MMM YYYY")}
                        </Text>
                      </Stack>
                    </HStack>

                    <Stack
                      flexDir="row"
                      alignItems="center"
                      borderBottomColor={"black"}
                      borderBottomWidth={2}
                      borderTopColor={"black"}
                      borderTopWidth={2}
                    >
                      <Stack
                        flex={1}
                        alignItems={"center"}
                        borderRightWidth={1}
                        borderRightColor={"black"}
                      >
                        <Text fontSize={"lg"}>QTY</Text>
                      </Stack>

                      <Stack
                        pl={3}
                        style={{
                          flex: 6,
                        }}
                      >
                        <Text fontSize={"lg"}>DESCRIPTION</Text>
                      </Stack>
                      <Stack
                        style={{
                          flex: 2,
                          alignItems: "center",
                        }}
                      >
                        <Text fontSize={"lg"}>AMOUNT</Text>
                      </Stack>
                    </Stack>

                    {orders.map((item, key) => (
                      <Stack
                        key={key}
                        flexDir="row"
                        py={2}
                        alignItems="center"
                        borderBottomColor={"black"}
                        borderBottomWidth={2}
                      >
                        <Stack
                          flex={1}
                          alignItems={"center"}
                          borderRightWidth={1}
                          borderRightColor={"black"}
                        >
                          <Text fontSize={"2xl"}>{item.packetNumber}</Text>
                        </Stack>

                        <Stack
                          pl={3}
                          style={{
                            textAlign: "left",
                            flex: 6,
                          }}
                        >
                          <Text fontSize={"xl"}>
                            {item.product}
                            {isFirstOrderType(item) && (
                              <>
                                {" - "}
                                {item.flavour} {item.flavour && ","} {item.size}
                                {"gms"}
                              </>
                            )}
                          </Text>
                        </Stack>
                        <Stack
                          style={{
                            flex: 2,
                            alignItems: "center",
                          }}
                        >
                          <Text fontSize={"xl"}>
                            {numberWithCommas(
                              parseInt(item.price) *
                                parseInt(item.packetNumber.toString())
                            )}
                          </Text>
                        </Stack>
                      </Stack>
                    ))}

                    <Stack alignItems={"flex-end"} pr={3} py={2}>
                      <Text fontSize="3xl" fontFamily="">
                        TOTAL{"     "}
                        {numberWithCommas(orders[0].totalAmount)}
                      </Text>
                    </Stack>

                    {Cookies.get("businessName") === "Keinth's Food" ? (
                      <Stack p={4} alignItems={"center"}>
                        {orders[0].traReceiptNumber === null ||
                        orders[0].traTime === null ||
                        orders[0].traReceiptNumber === "" ||
                        orders[0].traTime === "" ? null : (
                          <Stack
                            onClick={() => {
                              const order = orders[0];
                              const url = `${order.QRLink}${order.traCode}${order.traReceiptNumber}_${order.traTime}`;
                              window.open(url, "_blank");
                            }}
                          >
                            <Stack>
                              <QRCodeComponent
                                text={
                                  orders[0].QRLink +
                                  orders[0].traCode +
                                  orders[0].traReceiptNumber +
                                  "_" +
                                  orders[0].traTime
                                }
                              />
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    ) : (
                      <Stack p={4} alignItems={"center"}>
                        {orders[0].invoice_verification_code === null ||
                        orders[0].invoice_time === null ? null : (
                          <Stack
                            onClick={() => {
                              const order = orders[0];
                              const timeSplit = order.invoice_time.split(":");
                              const url = `${order.QRLink}${order.invoice_verification_code}_${timeSplit[0]}${timeSplit[1]}${timeSplit[2]}`;
                              window.open(url, "_blank");
                            }}
                          >
                            <Stack>
                              <QRCodeComponent
                                text={
                                  orders[0].QRLink +
                                  orders[0].invoice_verification_code +
                                  "_" +
                                  orders[0].invoice_time.split(":")[0] +
                                  orders[0].invoice_time.split(":")[1] +
                                  orders[0].invoice_time.split(":")[2]
                                }
                              />
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    )}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderDetailsScreen;
