import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  Input,
  Button,
  HStack,
  useToast,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  IconButton,
  CheckboxGroup,
  Checkbox,
  ModalFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import { expenseDataFormat, paymentDataFormat } from "../utils/types";
import moment from "moment";
import * as XLSX from "xlsx";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { FaTrash } from "react-icons/fa";
import ExpenseDetailsScreen from "./ExpenseDetails";

const Reports: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  //   FOR PAYMENTS
  const [retrievedPaymentData, setRetrievedPaymentData] = useState<paymentDataFormat[]>([]);
  const [filteredPaymentData, setFilteredPaymentData] = useState<paymentDataFormat[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof paymentDataFormat;
    direction: "ascending" | "descending";
  } | null>(null);

  //   FOR EXPENSES
  const [retrievedExpenseData, setRetrievedExpenseData] = useState<expenseDataFormat[]>([]);
  const [filteredExpenseData, setFilteredExpenseData] = useState<expenseDataFormat[]>([]);
  const [sortConfig1, setSortConfig1] = useState<{
    key: keyof expenseDataFormat;
    direction: "ascending" | "descending";
  } | null>(null);

  // Filters
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [paymentCustomerName, setPaymentCustomerName] = useState("");

  const [expenseStartDate, setExpenseStartDate] = useState("");
  const [expenseEndDate, setExpenseEndDate] = useState("");
  const [expenseCustomerName, setExpenseCustomerName] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");
  const toast = useToast();

  const [orderId, setOrderId] = useState<string>("");

  useEffect(() => {
    if (tabIndex === 0) {
      retrievePaymentData();
    }
    if (tabIndex === 1) {
      retrieveExpenseData();
    }
  }, [tabIndex]);

  const retrievePaymentData = async () => {
    try {
      setIsLoading(true);

      const headers = await getHeadersForRequest();

      const response = await axios.get<paymentDataFormat[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/getAll/absolute/income`,
        {
          headers,
        }
      );
      setRetrievedPaymentData(response.data);
      setFilteredPaymentData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const retrieveExpenseData = async () => {
    try {
      setIsLoading(true);

      const headers = await getHeadersForRequest();

      const response = await axios.get<expenseDataFormat[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/getAll/absolute/expenses`,
        {
          headers: headers,
        }
      );
      setRetrievedExpenseData(response.data);
      setFilteredExpenseData(response.data);
      setIsLoading(false);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const applyPaymentFilters = () => {
    let filteredData = [...retrievedPaymentData];

    if (paymentStartDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.paymentDate) >= new Date(paymentStartDate)
      );
    }

    if (paymentEndDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.paymentDate) <= new Date(paymentEndDate)
      );
    }

    if (paymentCustomerName) {
      filteredData = filteredData.filter((data) =>
        data.name.toLowerCase().includes(paymentCustomerName.toLowerCase())
      );
    }

    setFilteredPaymentData(filteredData);
  };

  const applyExpenseFilters = () => {
    let filteredData = [...retrievedExpenseData];

    if (expenseStartDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.invoiceDate) >= new Date(expenseStartDate)
      );
    }

    if (expenseEndDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.invoiceDate) <= new Date(expenseEndDate)
      );
    }

    if (expenseCustomerName) {
      filteredData = filteredData.filter((data) =>
        data.name.toLowerCase().includes(expenseCustomerName.toLowerCase())
      );
    }

    setFilteredExpenseData(filteredData);
  };

  const handleSort = (key: keyof paymentDataFormat) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredPaymentData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredPaymentData(sortedData);
  };

  const handleSort1 = (key: keyof expenseDataFormat) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig1 &&
      sortConfig1.key === key &&
      sortConfig1.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig1({ key, direction });
    const sortedData = [...filteredExpenseData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredExpenseData(sortedData);
  };

  const exportToExcel = (data: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleTransactionClick = (transaction: expenseDataFormat) => {
    setShowDetailsModal(true);

    let delivered = transaction.deliveryStatus ? "delivered" : "";
    let paid = transaction.paymentStatus ? "paid" : "";
    let physical = transaction.physicalDeliveryNote ? "physical" : "";
    let tra = transaction.traReceiptNumber && transaction.traTime ? "tra" : "";
    let newGroupValues = [delivered, paid, physical, tra];

    setGroupValues(newGroupValues);
    setDeliveryNoteNumber(transaction.deliveryNoteNumber);
    setTransactionId(transaction.id);
    setTraReceiptNumber(transaction.traReceiptNumber);
    setTraReceiptTime(transaction.traTime);
  };

  const updateTransactionDetails = async () => {
    try {
      const data = {
        paymentStatus: groupValues.includes("paid"),
        paymentDate: groupValues.includes("paid") ? new Date() : null,
        transactionId: transactionId,
        deliveryStatus: groupValues.includes("delivered"),
        deliveryDate: groupValues.includes("delivered") ? new Date() : null,
        invoiceDate: new Date(),
        physicalDeliveryNote: groupValues.includes("physical"),
        deliveryNoteNumber: groupValues.includes("physical")
          ? deliveryNoteNumber
          : "",
        traReceiptNumber: traReceiptNumber,
        traReceiptTime: traReceiptTime,
      };

      if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
        toast({ title: "Invalid Delivery Note Number" });
        return;
      }

      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/update`,
          {
            data,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: "Transaction details updated successfully",
            duration: 3000,
            status: "success",
          });
          setShowDetailsModal(false);
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error updating transaction",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {}
  };

  const voidTransaction = async () => {
    try {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/delete`,
          {
            transactionId: transactionId,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: "Transaction VOID successful",
            duration: 3000,
            status: "success",
          });
          setShowDetailsModal(false);
          retrieveExpenseData();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error, please try again later",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {
      // toast.show({ description: error.toString() });
      console.error(error);
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav />
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          <Tabs
            isLazy
            onChange={(index) => {
              setTabIndex(index);
              console.log("Index " + index);
            }}
            index={tabIndex}
          >
            <TabList>
              <Tab>Customer Payments</Tab>
              <Tab>Expenses</Tab>
            </TabList>
            <TabPanels>
              {/* CUSTOMER PAYMENTS*/}
              <TabPanel>
                <Box mb={10}>
                  <HStack flexWrap={"wrap"}>
                    <HStack pr={5}>
                      <Stack>
                        <Text>Start Date</Text>
                      </Stack>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="Start Date"
                          type="date"
                          value={paymentStartDate}
                          onChange={(e) => setPaymentStartDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Stack>
                        <Text>End Date</Text>{" "}
                      </Stack>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="End Date"
                          type="date"
                          value={paymentEndDate}
                          onChange={(e) => setPaymentEndDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Stack>
                        <Text>Name</Text>{" "}
                      </Stack>
                      <Stack>
                        <Input
                          placeholder="Customer Name"
                          value={paymentCustomerName}
                          onChange={(e) =>
                            setPaymentCustomerName(e.target.value)
                          }
                        />
                      </Stack>
                    </HStack>

                    <HStack>
                      {/* <Text></Text> */}
                      <Button onClick={applyPaymentFilters}>
                        Apply Filters
                      </Button>
                      <Button
                        onClick={() =>
                          exportToExcel(filteredPaymentData, "Payments")
                        }
                      >
                        Export to Excel
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
                <TableContainer>
                  <Table variant="striped">
                    <TableCaption>Data fetched from the server</TableCaption>
                    <Thead>
                      <Tr>
                        <Th fontSize={"lg"} onClick={() => handleSort("id")}>
                          ID
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort("paymentDate")}
                        >
                          Date
                        </Th>
                        <Th fontSize={"lg"} onClick={() => handleSort("name")}>
                          Customer Name
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort("totalAmount")}
                          isNumeric
                        >
                          Total Amount
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredPaymentData.map(
                        (data: paymentDataFormat, key) => (
                          <Tr
                            key={key}
                            backgroundColor={data.isVoid ? "red" : "unset"}
                          >
                            <Td>{data.id}</Td>
                            <Td>
                              {moment(data.paymentDate).format("DD MMM YYYY")}
                            </Td>
                            <Td>{data.name}</Td>
                            <Td isNumeric>
                              {numberWithCommas(data.totalAmount)}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>id</Th>
                        <Th>paymentDate</Th>
                        <Th>name</Th>
                        <Th isNumeric>totalAmount</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </TabPanel>

              {/* EXPENSES */}
              <TabPanel>
                <Box mb={10}>
                  <HStack direction="row" spacing={4} flexWrap={"wrap"}>
                    <HStack pr={5}>
                      <Text>Start Date</Text>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="Start Date"
                          type="date"
                          value={expenseStartDate}
                          onChange={(e) => setExpenseStartDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>
                    <HStack pr={5}>
                      <Text>End Date</Text>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="End Date"
                          type="date"
                          value={expenseEndDate}
                          onChange={(e) => setExpenseEndDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Text>Description</Text>
                      <Stack>
                        <Input
                          placeholder="Description"
                          value={expenseCustomerName}
                          onChange={(e) =>
                            setExpenseCustomerName(e.target.value)
                          }
                        />
                      </Stack>
                    </HStack>
                    <HStack>
                      <Button onClick={applyExpenseFilters}>
                        Apply Filters
                      </Button>
                      <Button
                        onClick={() =>
                          exportToExcel(filteredExpenseData, "Expenses")
                        }
                      >
                        Export to Excel
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
                <TableContainer>
                  <Table variant={"striped"}>
                    <TableCaption>Data fetched from the server</TableCaption>
                    <Thead>
                      <Tr>
                        <Th fontSize={"lg"} onClick={() => handleSort1("id")}>
                          ID
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort1("invoiceDate")}
                        >
                          Date
                        </Th>
                        <Th fontSize={"lg"} onClick={() => handleSort1("name")}>
                          Description
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort1("paymentStatus")}
                        >
                          Payment Status
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort1("totalAmount")}
                          isNumeric
                        >
                          Total Amount
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredExpenseData.map(
                        (data: expenseDataFormat, key) => (
                          <Tr
                            key={key}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              if (data.isVoid) {
                              } else {
                                handleTransactionClick(data);
                              }
                            }}
                            onClick={() => {
                              setShowPreviewModal(true);
                              setOrderId(data.transactionNumber.toString());
                            }}
                          >
                            <Td>{data.transactionNumber}</Td>
                            <Td>
                              {moment(data.invoiceDate).format("DD MMM YYYY")}
                            </Td>
                            <Td>{data.name}</Td>
                            <Td
                              fontWeight={"bolder"}
                              color={data.paymentStatus ? "unset" : "red"}
                            >
                              {data.paymentStatus ? "PAID" : "NOT PAID"}
                            </Td>
                            <Td isNumeric>
                              {numberWithCommas(data.price * data.qty)}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      )}
      {/* TRANSACTION DETAILS MODAL */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
      >
        <ModalContent maxWidth="350">
          <ModalCloseButton />
          <ModalHeader>Transaction Details</ModalHeader>
          <ModalBody>
            <HStack justifyContent={"space-between"}>
              <Text>Order details:</Text>

              <IconButton
                size="md"
                fontSize="lg"
                variant="ghost"
                color="current"
                marginLeft="2"
                onClick={() => setShowConfirmationModal(true)}
                icon={<FaTrash color="red" />}
                aria-label={`Go to home`}
              />
            </HStack>

            <Stack>
              <CheckboxGroup
                onChange={(values: string[]) => setGroupValues(values)}
                value={groupValues}
              >
                <Checkbox value="paid" my={2}>
                  Paid
                </Checkbox>
                <Checkbox value="delivered" my={2}>
                  Delivered
                </Checkbox>
                <Checkbox value="physical" my={2}>
                  Physical Delivery Note
                </Checkbox>
                <Checkbox value="tra" my={2}>
                  TRA Receipt
                </Checkbox>
              </CheckboxGroup>
            </Stack>

            <Stack>
              {groupValues.includes("physical") && (
                <Input
                  fontSize="md"
                  pl={5}
                  backgroundColor="transparent"
                  variant={"underlined"}
                  defaultValue={deliveryNoteNumber}
                  onChange={(e) => setDeliveryNoteNumber(e.target.value)}
                  placeholder={"Enter delivery note number"}
                />
              )}
            </Stack>
            <Stack>
              {groupValues.includes("tra") && (
                <>
                  <Input
                    // color={"white"}
                    fontSize="md"
                    pl={5}
                    // keyboardType="number-pad"
                    backgroundColor="transparent"
                    variant={"underlined"}
                    defaultValue={traReceiptNumber}
                    onChange={(e) => setTraReceiptNumber(e.target.value)}
                    placeholder={"Enter TRA Receipt Number"}
                  />

                  <Input
                    fontSize="md"
                    pl={5}
                    backgroundColor="transparent"
                    variant={"underlined"}
                    defaultValue={traReceiptTime}
                    onChange={(e) => setTraReceiptTime(e.target.value)}
                    placeholder={"Enter Receipt Time"}
                  />
                </>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={() => {
                  setShowDetailsModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateTransactionDetails();
                }}
              >
                Update
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* CONFIRMATION MODAL */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <ModalContent maxWidth="350">
          <ModalCloseButton />
          <ModalHeader>Alert</ModalHeader>
          <ModalBody>
            <Stack>
              <Text textAlign={"center"}>
                Are you sure you want to VOID this receipt?
              </Text>
              <Text textAlign={"center"}>THIS ACTION IS IRREVERSIBLE!</Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                No
              </Button>
              <Button
                bg={"red"}
                onClick={() => {
                  voidTransaction();
                }}
              >
                Yes
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* PREVIEW MODAL */}
      <Modal
        size="xl"
        isCentered
        closeOnOverlayClick={false}
        isOpen={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Preview</ModalHeader>
          <ModalBody>
            <Stack>
              <ExpenseDetailsScreen ID={orderId} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={() => {
                  setShowPreviewModal(false);
                }}
              >
                Close
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Reports;
