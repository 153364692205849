import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./components/ColorModeSwitcher";
import { Logo } from "./Logo";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import SalesOrder from "./screens/SalesOrder";
import Cookies from "js-cookie";
import Reports from "./screens/Report";
import PurchaseOrder from "./screens/PurchaseOrder";
import Analytics from "./screens/Analytics";
import CustomerScreen from "./screens/Customers";
import PendingOrdersScreen from "./screens/PendingOrders";
import RecentOrdersScreen from "./screens/RecentOrders";
import OrderDetailsScreen from "./screens/OrderDetails";
import RecentPaymentsScreen from "./screens/RecentPayments";
import ExpenseDetailsScreen from "./screens/ExpenseDetails";
import RecentExpensesScreen from "./screens/RecentExpenses";
import CustomerOrdersScreen from "./screens/CustomerOrders";
import CustomerPaymentsScreen from "./screens/CustomerPayments";
import PaymentsScreen from "./screens/Payments";
import Logins from "./screens/Logins";
import ChangePassword from "./screens/ChangePassword";
import AccountsScreen from "./screens/Accounts";
import AccountTransferScreen from "./screens/AccountTransfer";
import AccountStatement from "./screens/AccountStatement";
import ProductsScreen from "./screens/Products";
import SalesOrderGeneral from "./screens/SalesOrderGeneral";
import SupplierScreen from "./screens/Suppliers";
import AccountTransferExternalScreen from "./screens/AccountTransferExternal";
import CustomerAnalyticsScreen from "./screens/CustomerAnalytics";

const App = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const user = Cookies.get("userToken");
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/Logins" element={<Logins />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/dashboard/SalesCalculator" element={<SalesOrder />} />
            <Route path="/dashboard/SalesCalculatorGeneral" element={<SalesOrderGeneral />} />
            <Route path="/dashboard/ExpensesCalculator" element={<PurchaseOrder />} />            
            <Route path="/dashboard/Payments" element={<PaymentsScreen />} />        
            <Route path="/dashboard/Transfers" element={<AccountTransferScreen />} />
            <Route path="/dashboard/TransfersExternal" element={<AccountTransferExternalScreen />} />
            <Route path="/dashboard/Reports" element={<Reports />} />
            <Route path="/dashboard/Analytics" element={<Analytics />} />
            <Route path="/dashboard/Products" element={<ProductsScreen />} />
            <Route path="/dashboard/Customers" element={<CustomerScreen />} />
            <Route path="/dashboard/Suppliers" element={<SupplierScreen />} />
            <Route path="/dashboard/Accounts" element={<AccountsScreen />} />
            <Route path="/dashboard/PendingOrders" element={<PendingOrdersScreen />} /> 
            <Route path="/dashboard/RecentOrders" element={<RecentOrdersScreen />} />
            <Route path="/OrderDetails/:orderId" element={<OrderDetailsScreen />} />
            <Route path="/dashboard/RecentPayments" element={<RecentPaymentsScreen />} />
            <Route path="/dashboard/RecentExpenses" element={<RecentExpensesScreen />} />
            <Route path="/ExpenseDetails/:orderId" element={<ExpenseDetailsScreen ID={""}/>} />
            <Route path="/AccountStatement/:accountId/:accountName" element={<AccountStatement/>} />
            <Route path="/CustomerOrders/:customerId/:customerName" element={<CustomerOrdersScreen />} />
            <Route path="/CustomerPayments/:customerId/:customerName" element={<CustomerPaymentsScreen />} />
            <Route path="/CustomerAnalytics/:customerId/:customerName" element={<CustomerAnalyticsScreen />} />
          </Routes>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

const AppWrapper = () => (
    <Router>
      <App />
    </Router>
);
export default AppWrapper;
