import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { Button, Center, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { Loading } from "../components/Loading";
import moment from "moment";
import QRCode from "react-qr-code";
import Nav from "../components/navbar";
import Cookies from "js-cookie";

const logo = require("../resources/images/logo.jpg");
const logo2 = require("../resources/images/koas-logo.png");

type OrderList = {
  id: number;
  description: string;
  qty: string;
  price: string;
  transactionNumber: number;
  userId: string;
  transactionDate: Date;
  totalAmount: number;
  customerId: number;
  paymentStatus: number;
  paymentDate: Date;
  deliveryStatus: boolean;
  deliveryDate: Date;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  transactionType: number;
  supplierId: number;
  invoiceDate: Date;
  isVoid: boolean;
  name: string;
  tin: string;
  phoneNumber: string;
  location: string;
  traCode: string;
  traTime: string;
  QRLink: string;
  traReceiptNumber: string;
};

interface ExpenseDetailsScreenProps {
  ID: string;
}

const ExpenseDetailsScreen: React.FC<ExpenseDetailsScreenProps> = ({ ID }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderList[]>([]);
  const [resourceFound, setResourceFound] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const { orderId } = useParams<{ orderId: string }>();

  useEffect(() => {
    setIsLoading(true);
    getOrderDetails();
    console.log(orderId);
  }, []);

  useEffect(() => {
    checkFileExists();
  }, [orders]);

  const checkFileExists = async () => {
    const headers = await getHeadersForRequest();

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/check-file/${orders[0].transactionNumber.toString()}.pdf`,
        { headers }
      );
      setIsLoading(false);
      if (response.status === 200) {
        setResourceFound(true);
      } else {
        setResourceFound(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getOrderDetails = async () => {
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      orderid: orderId ? orderId : ID,
    };
    const headers = { ...oldHeaders, ...newHeaders };

    try {
      const response = await axios.get<OrderList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/orderdetails/expenses`,
        { headers }
      );

      setOrders(response.data);
      setIsLoading(false);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const downloadResources = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    console.log("Starting download...");

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/download/${orders[0].transactionNumber.toString()}.pdf`,
        { headers, responseType: "arraybuffer" } // Ensure raw binary data
      );

      // Step 1: Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Step 2: Create a URL for the Blob
      const fileURL = URL.createObjectURL(file);

      // Step 3: Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${orders[0].transactionNumber}.pdf`); // File name
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Clean up link element

      // Clean up the URL after the download completes
      URL.revokeObjectURL(fileURL);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
        alert("File not found. Please try again later.");
      } else {
        console.error("Error downloading the PDF:", error);
        alert(
          "An error occurred while downloading the file. Please try again."
        );
      }
    }
  };

  const viewResource = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    console.log("Starting file view...");

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/download/${orders[0].transactionNumber.toString()}.pdf`,
        { headers, responseType: "arraybuffer" } // Ensure raw binary data
      );

      // Step 1: Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Step 2: Create a URL for the Blob
      const fileURL = URL.createObjectURL(file);

      // Step 3: Open the PDF in a new tab
      window.open(fileURL);

      // Clean up the URL after the file is opened
      URL.revokeObjectURL(fileURL);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
        alert("File not found. Please try again later.");
      } else {
        console.error("Error viewing the PDF:", error);
        alert("An error occurred while viewing the file. Please try again.");
      }
    }
  };

  return (
    <Stack>
      <Stack>
        {ID !== "" ? null : (
          <Stack position="sticky" top={0} zIndex={1} width="full">
            <Nav />
          </Stack>
        )}
        <Stack>
          <Stack px={1}>
            {isLoading ? (
              <Center h={600}>
                <Loading />
              </Center>
            ) : (
              <>
                <Stack p={1}>
                  {orders.length > 0 && (
                    <Stack borderWidth={2} borderRadius={5}>
                      <HStack
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        p={1}
                        pl={3}
                      >
                        <Stack textAlign={"left"}>
                          <Text fontSize="2xl" fontFamily="">
                            PO# {orders[0].transactionNumber}
                          </Text>
                          <Text>
                            Delivery Note #{orders[0].deliveryNoteNumber}
                          </Text>

                          <Text
                            fontSize="lg"
                            fontFamily=""
                            mb={-2}
                            py={5}
                            fontWeight={"bolder"}
                            color={orders[0].paymentStatus ? "green" : "red"}
                          >
                            {orders[0].paymentStatus ? "PAID" : "NOT PAID"}
                          </Text>
                          <Text fontSize="lg" fontFamily="" mb={-2}>
                            Supplier
                          </Text>
                          <Text fontSize="lg" fontFamily="">
                            {orders[0].name ? orders[0].name : "Cash"}
                          </Text>
                          <Text fontSize="lg" fontFamily="">
                            {orders[0].tin}
                          </Text>
                        </Stack>
                        <Stack>
                          <Image
                            bg={"white"}
                            boxSize={"150px"}
                            src={
                              Cookies.get("businessName") === "Keinth's Food"
                                ? logo
                                : logo2
                            }
                          />

                          <Text
                            mb={-2}
                            fontSize="lg"
                            fontFamily=""
                            textAlign={"right"}
                          >
                            PO Date
                          </Text>
                          <Text fontSize="lg" fontFamily="" textAlign={"right"}>
                            {moment(orders[0].invoiceDate).format(
                              "DD MMM YYYY"
                            )}
                          </Text>
                        </Stack>
                      </HStack>

                      <Stack
                        flexDir="row"
                        alignItems="center"
                        borderBottomColor={"black"}
                        borderBottomWidth={2}
                        borderTopColor={"black"}
                        borderTopWidth={2}
                      >
                        <Stack
                          flex={1}
                          alignItems={"center"}
                          borderRightWidth={1}
                          borderRightColor={"black"}
                        >
                          <Text fontSize={"lg"}>QTY</Text>
                        </Stack>

                        <Stack
                          pl={3}
                          style={{
                            flex: 6,
                          }}
                        >
                          <Text fontSize={"lg"}>DESCRIPTION</Text>
                        </Stack>
                        <Stack
                          style={{
                            flex: 2,
                            alignItems: "center",
                          }}
                        >
                          <Text fontSize={"lg"}>AMOUNT</Text>
                        </Stack>
                      </Stack>

                      {/* TO DO: IMPLEMENT THE ONE FROM NATIVE BASE */}

                      {orders.map((item, key) => (
                        <Stack
                          flexDir="row"
                          py={2}
                          alignItems="center"
                          borderBottomColor={"black"}
                          borderBottomWidth={2}
                        >
                          <Stack
                            flex={1}
                            alignItems={"center"}
                            borderRightWidth={1}
                            borderRightColor={"black"}
                          >
                            <Text fontSize={"2xl"}>{item.qty}</Text>
                          </Stack>

                          <Stack
                            pl={3}
                            style={{
                              textAlign: "left",
                              flex: 6,
                            }}
                          >
                            <Text
                              fontSize={"xl"}
                              // color={"white"}
                            >
                              {item.description}
                            </Text>
                          </Stack>
                          <Stack
                            style={{
                              flex: 2,
                              alignItems: "center",
                            }}
                          >
                            <Text fontSize={"xl"}>
                              {numberWithCommas(
                                parseInt(item.price) * parseInt(item.qty)
                              )}
                            </Text>
                          </Stack>
                        </Stack>
                      ))}

                      {/* END OF LIST */}

                      <Stack alignItems={"flex-end"} pr={3} py={2}>
                        <Text fontSize="3xl" fontFamily="">
                          TOTAL{"     "}
                          {numberWithCommas(orders[0].totalAmount)}
                        </Text>
                      </Stack>

                      {resourceFound && (
                        <HStack
                          alignItems={"center"}
                          justifyContent={"space-evenly"}
                          pt={2}
                          // position={"absolute"}
                          // right={10}
                          // bottom={10}
                        >
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              viewResource();
                            }}
                          >
                            Preview
                          </Button>
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              downloadResources();
                            }}
                          >
                            Download
                          </Button>
                        </HStack>
                      )}

                      <Stack p={4} alignItems={"center"}>
                        {orders[0].traReceiptNumber === null ||
                        orders[0].traTime === null ||
                        orders[0].traReceiptNumber === "" ||
                        orders[0].traTime === "" ? null : (
                          <Stack
                            onClick={() => {
                              const order = orders[0];
                              const url = `${order.QRLink}${order.traCode}${order.traReceiptNumber}_${order.traTime}`;
                              window.open(url, "_blank");
                            }}
                          >
                            <Stack>
                              <QRCode
                                value={
                                  orders[0].QRLink +
                                  orders[0].traCode +
                                  orders[0].traReceiptNumber +
                                  "_" +
                                  orders[0].traTime
                                }
                              />
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ExpenseDetailsScreen;
