import { useEffect, useState } from "react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import { ProductList } from "../utils/types";
import {
  Button,
  ButtonGroup,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { AddProduct } from "../components/AddProduct";

const ProductsScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  // const [selectedCustomer, setSelectedCustomer] = useState<CustomerList>();
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductList[]>([]);
  const [productDetails, setProductDetails] = useState<ProductList[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductList[]>([]);
  const [searchTerm, setSearchTerm] = useState<String>("");

  useEffect(() => {
    setIsLoading(true);
    getAllProducts();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    filterCustomers();
  }, [searchTerm, products]);

  const getAllProducts = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<ProductList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/products/getAll`,
        { headers }
      );
      setProducts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const filterCustomers = () => {
    if (!searchTerm) {
      setFilteredProducts(products);
      setIsLoading(false);
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData = products.filter((product) =>
        Object.keys(product).some((key: any) =>
          product[key as keyof ProductList]
            .toString()
            .toLowerCase()
            .includes(lowercasedFilter)
        )
      );
      setFilteredProducts(filteredData);
      setIsLoading(false);
    }
  };

  const loadProductDetails = async (id: number) => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/products/getdetails`,
        { id: id },
        { headers }
      );
      setModalIsLoading(false);
      setProductDetails(response.data);
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav />
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Loading />
          ) : (
            <Stack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <InputGroup maxW={500}>
                  <Input
                    placeholder="Search for a product..."
                    width="100%"
                    //   bg="white"
                    borderRadius="4"
                    py="3"
                    px="1"
                    fontSize="14"
                    onChange={(text) => setSearchTerm(text.target.value)}
                    autoCapitalize="none"
                  />
                  <InputRightAddon>Search</InputRightAddon>
                </InputGroup>

                <Button
                  onClick={() => {
                    setIsEditMode(false);
                    setIsAddMode(true);
                  }}
                >
                  Add Product
                </Button>
              </HStack>

              <Stack h={10} />

              {filteredProducts.length > 0 ? (
                <TableContainer maxW={"100%"}>
                  <Table variant="striped">
                    <TableCaption>List of products</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Product Name</Th>
                        <Th>Description</Th>
                        <Th isNumeric>Tax Category</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredProducts.map((product: any, index: number) => (
                        <Tr
                          onClick={() => {
                            setShowDetailsModal(true);
                            const id = parseInt(product.id);
                            loadProductDetails(id);
                          }}
                        >
                          <Td>{product.id} </Td>
                          <Td>{product.name}</Td>
                          <Td>{product.description}</Td>
                          <Td isNumeric>
                            {product.taxCategory === "1"
                              ? "18%"
                              : product.taxCategory === "2"
                              ? "Zero Rated"
                              : "Exempt"}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>ID</Th>
                        <Th>Product Name</Th>
                        <Th>Description</Th>
                        <Th isNumeric>Tax Category</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              ) : (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    There are no Products
                  </Text>
                  <Text fontSize={20} textAlign={"center"}>
                    Click the "Add Product" button to add one!
                  </Text>
                </Stack>
              )}
            </Stack>
          )}

          {/* CUSTOMER DETAILS MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
          >
            {" "}
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Product Details</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  productDetails.length > 0 && (
                    <>
                      <Text>Name: {productDetails[0].name}</Text>
                      <Text>Description: {productDetails[0].description}</Text>
                      <Text>
                        Tax Category:{" "}
                        {productDetails[0].taxCategory === "1"
                          ? "18%"
                          : productDetails[0].taxCategory === "2"
                          ? "Zero Rated"
                          : "Exempt"}
                      </Text>
                      <Text>
                        Status:{" "}
                        {productDetails[0].isVoid ? "Disabled" : "Active"}
                      </Text>
                    </>
                  )
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowDetailsModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      setShowDetailsModal(false);
                      setIsEditMode(true);
                      setIsAddMode(false);
                      setShowDetailsModal(false);
                    }}
                  >
                    Edit
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* ADD CUSTOMER MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isAddMode}
            onClose={() => {
              setIsAddMode(false);
              getAllProducts();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Add Product</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  <Stack flex={1}>
                    <Stack alignItems={"center"}>
                      <Stack>
                        <AddProduct
                          name={""}
                          id={""}
                          taxCategory={""}
                          description={""}
                          isVoid={false}
                          isEdit={isEditMode}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* EDIT CUSTOMER MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isEditMode}
            onClose={() => {
              setIsEditMode(false);
              getAllProducts();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Customer Details</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  isEditMode && (
                    <Stack flex={1}>
                      <Stack alignItems={"center"}>
                        <Stack>
                          <AddProduct
                            name={productDetails ? productDetails[0].name : ""}
                            id={
                              productDetails
                                ? productDetails[0].id.toString()
                                : ""
                            }
                            description={
                              productDetails
                                ? productDetails[0].description
                                : ""
                            }
                            taxCategory={
                              productDetails
                                ? productDetails[0].taxCategory
                                : ""
                            }
                            isVoid={
                              productDetails ? productDetails[0].isVoid : false
                            }
                            isEdit={isEditMode}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProductsScreen;
