import { Stack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";

type DataPoint = {
  primary: string; // Ensure primary is a string (month names)
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type ApiResponse = {
  orders: number[];
  payments: number[];
  expenses: number[];
};

const processData = (data: ApiResponse, t: (key: string) => string): Series[] => {
  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];
  return [
    {
      label: t("Orders"),
      data: data.orders.map((value, index) => ({
        primary: months[index],
        secondary: value,
      })),
    },
    {
      label: t("Payments"),
      data: data.payments.map((value, index) => ({
        primary: months[index],
        secondary: value,
      })),
    },
    {
      label: t("Expenses"),
      data: data.expenses.map((value, index) => ({
        primary: months[index],
        secondary: value,
      })),
    },
  ];
};

export default function Bar() {
  const [data, setData] = useState<Series[]>([]);
  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = await getHeadersForRequest();

        const response = await axios.get<ApiResponse>(
          `${process.env.REACT_APP_TEST_API_URL}/analytics/monthly/all`,
          {
            headers,
          }
        );
        console.log("API Response:", response.data); // Log API response
        const processedData = processData(response.data, t);
        console.log("Processed Data:", processedData); // Log processed data
        setData(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [t]);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band", // Set the scale type explicitly
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear", // Set the scale type explicitly
      },
    ],
    []
  );

  return (
    <Stack w="100%" h={500}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          dark: useColorModeValue(false, true),
        }}
      />
    </Stack>
  );
}
