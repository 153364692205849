import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { CustomerList } from "../utils/types";
import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { AddCustomer } from "../components/AddCustomer";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";

const CustomerScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [customerDetails, setCustomerDetails] = useState<CustomerList[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerList[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<String>("");
  const [totalOwed, setTotalOwed] = useState<number>(0);
  const [count, setCount] = useState(0);

  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    setIsLoading(true);
    getAllCustomers();
    getTotalBalance();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    filterCustomers();
  }, [searchTerm, customers]);

  const getCustomerStatement = async () => {
    const headers = await getHeadersForRequest();
    console.log(customerDetails[0].id);

    try {
      const url = `${process.env.REACT_APP_TEST_API_URL}/payments/exportStatement/${customerDetails[0].id}`;
      console.log(url);

      const response = await axios.get(url, {
        headers,
        responseType: "blob", // Ensure the response is a Blob
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.setAttribute(
        "download",
        `${customerDetails[0].name}-${moment(new Date()).format(
          "DDMMMYY"
        )}-Statement.xlsx`
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setIsLoading(false);
    } catch (error) {
      console.error("Error exporting data:", error);
      setIsLoading(false);
    }
  };

  const getAllCustomers = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<CustomerList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/payments/customerBalances`,
        { headers }
      );
      setTotalOwed(
        response.data.reduce(
          (total, customer) => total + Number(customer.balance),
          0
        )
      );
      // Sort filtered customers by balance in descending order
      const sortedFilteredData = response.data.sort(
        (a, b) => Number(b.balance) - Number(a.balance)
      );
      setCustomers(sortedFilteredData);
      setCount(1);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const filterCustomers = () => {
    if (!searchTerm) {
      setFilteredCustomers(customers);
      if (count === 1) {
        setIsLoading(false);
      }
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData =
        customers !== null
          ? customers.filter((customer) =>
              Object.keys(customer).some((key: any) => {
                const value = customer[key as keyof CustomerList];
                return (
                  value !== null &&
                  value.toString().toLowerCase().includes(lowercasedFilter)
                );
              })
            )
          : [];
      // Sort filtered customers by balance in descending order
      const sortedFilteredData = filteredData.sort(
        (a, b) => Number(b.balance) - Number(a.balance)
      );
      setFilteredCustomers(sortedFilteredData);
      setIsLoading(false);
    }
  };

  const loadCustomerDetails = async (id: number) => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/customers/getdetails`,
        { id: id },
        { headers }
      );
      setModalIsLoading(false);
      setCustomerDetails(response.data);
    } catch (error) {
      console.error(error);
      setModalIsLoading(false);
    }
  };

  // Function to calculate the total balance
  const getTotalBalance = () => {
    const total = customers.reduce(
      (total, customer) => total + Number(customer.balance),
      0
    );
    console.log(total);
  };

  const navigateToOrders = (customerId: number, customerName: string) => {
    navigate(`/CustomerOrders/${customerId}/${customerName}`);
  };

  const navigateToPayments = (customerId: number, customerName: string) => {
    navigate(`/CustomerPayments/${customerId}/${customerName}`);
  };

  const navigateToAnalytics = (customerId: number, customerName: string) => {
    navigate(`/CustomerAnalytics/${customerId}/${customerName}`);
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav />
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <HStack
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <InputGroup maxW={500}>
                  <Input
                    placeholder={t("Search for a customer...")}
                    width="100%"
                    borderRadius="4"
                    py="3"
                    px="1"
                    fontSize="14"
                    onChange={(text) => setSearchTerm(text.target.value)}
                    autoCapitalize="none"
                  />
                  <InputRightAddon>{t("Search")}</InputRightAddon>
                </InputGroup>

                <Text fontSize={20} textAlign={"center"}>
                  {t("Total recievable:")} {numberWithCommas(totalOwed)}/=
                </Text>

                <Button
                  onClick={() => {
                    setIsEditMode(false);
                    setIsAddMode(true);
                  }}
                >
                  {t("Add Customer")}
                </Button>
              </HStack>

              <Stack h={10} />

              {filteredCustomers.length > 0 ? (
                <TableContainer>
                  <Table variant="striped">
                    <TableCaption>{t("List of customers")}</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>ID</Th>
                        <Th>{t("Customer Name")}</Th>
                        <Th>{t("TIN")}</Th>
                        <Th isNumeric>{t("Balance")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredCustomers.map((customer: any, index: number) => (
                        <Tr
                          onClick={() => {
                            setShowDetailsModal(true);
                            const id = parseInt(customer.customerId);
                            loadCustomerDetails(id);
                          }}
                        >
                          <Td>{customer.customerId} </Td>
                          <Td>{customer.customerName}</Td>
                          <Td>{customer.customerTIN}</Td>
                          <Td isNumeric>
                            {numberWithCommas(customer.balance)}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>ID</Th>
                        <Th>{t("Customer Name")}</Th>
                        <Th>{t("TIN")}</Th>
                        <Th isNumeric>{t("Balance")}</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              ) : (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    {t("There are no Customers")}
                  </Text>
                  <Text
                    fontSize={20}
                    textAlign={"center"}
                    // color={primary}
                  >
                    Click the + button to add one!
                  </Text>
                </Stack>
              )}
            </Stack>
          )}

          {/* CUSTOMER DETAILS MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Customer Details")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  customerDetails.length > 0 && (
                    <>
                      <Text>
                        {t("Name:")} {customerDetails[0].name}
                      </Text>
                      <Text>
                        {t("ID Number:")} {customerDetails[0].tin}
                      </Text>
                      <Text>
                        {t("Phone Number:")} {customerDetails[0].phoneNumber}
                      </Text>
                      <Text>
                        {t("ID Type:")} {customerDetails[0].category}
                      </Text>

                      <br />

                      <HStack justifyContent={"space-around"}>
                        <Button
                          onClick={() => getCustomerStatement()}
                          // bg={primary}
                          width={"100"}
                          flex={1}
                        >
                          {t("Statement")}
                        </Button>
                        <Button
                          onClick={() =>
                            navigateToAnalytics(
                              customerDetails[0].id,
                              customerDetails[0].name
                            )
                          }
                          // bg={primary}
                          width={"100"}
                          flex={1}
                        >
                          {t("Analytics")}
                        </Button>
                      </HStack>

                      <br />

                      <HStack justifyContent={"space-around"}>
                        <Button
                          onClick={() =>
                            navigateToPayments(
                              customerDetails[0].id,
                              customerDetails[0].name
                            )
                          }
                          // bg={primary}
                          width={"100"}
                          flex={1}
                        >
                          {t("Payments")}
                        </Button>
                        <Button
                          onClick={() =>
                            navigateToOrders(
                              customerDetails[0].id,
                              customerDetails[0].name
                            )
                          }
                          width={"100"}
                          flex={1}
                        >
                          {t("Orders")}
                        </Button>
                      </HStack>
                    </>
                  )
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowDetailsModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    disabled={isLoading}
                    // bg={primary}
                    onClick={() => {
                      setShowDetailsModal(false);
                      setIsEditMode(true);
                      setIsAddMode(false);
                      setShowDetailsModal(false);
                    }}
                  >
                    {t("Edit")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* ADD CUSTOMER MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isAddMode}
            onClose={() => {
              setIsAddMode(false);
              getAllCustomers();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Add Customer")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  <Stack flex={1}>
                    <Stack alignItems={"center"}>
                      <Stack>
                        <AddCustomer
                          name={""}
                          id={""}
                          location={""}
                          phoneNumber={""}
                          tin={""}
                          isEdit={isEditMode}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* EDIT CUSTOMER MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={isEditMode}
            onClose={() => {
              setIsEditMode(false);
              getAllCustomers();
            }}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Customer Details")}</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Loading />
                ) : (
                  isEditMode && (
                    <Stack flex={1}>
                      <Stack alignItems={"center"}>
                        <Stack>
                          <AddCustomer
                            name={
                              customerDetails ? customerDetails[0].name : ""
                            }
                            id={
                              customerDetails
                                ? customerDetails[0].id.toString()
                                : ""
                            }
                            location={
                              customerDetails ? customerDetails[0].location : ""
                            }
                            phoneNumber={
                              customerDetails
                                ? customerDetails[0].phoneNumber
                                : ""
                            }
                            tin={customerDetails ? customerDetails[0].tin : ""}
                            isEdit={isEditMode}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerScreen;
