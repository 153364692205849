import { Stack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { AxisOptions, Chart } from "react-charts";

interface MonthlySales {
  [key: string]: number;
}

interface LineProps {
  data: MonthlySales;
}

export default function Line({ data }: LineProps) {
  // Transform the data object into an array of series
  const transformedData = [
    {
      label: "Monthly Sales",
      data: Object.entries(data)
        .map(([date, value]) => {
          const parsedDate = new Date(date + "-01"); // Ensure the date is correctly parsed
          console.log(`Parsed date: ${parsedDate}, Value: ${value}`); // Debugging log
          return {
            primary: parsedDate,
            secondary: value,
          };
        })
        .sort((a, b) => a.primary.getTime() - b.primary.getTime()), // Sort the dates
    },
  ];

  console.log("Transformed Data: ", JSON.stringify(transformedData));

  const primaryAxis = React.useMemo<
    AxisOptions<typeof transformedData[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary as Date,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof transformedData[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "area",
      },
    ],
    []
  );

  return (
    <Stack w="100%" h={500}>
      <Chart
        options={{
          data: transformedData,
          primaryAxis,
          secondaryAxes,
          dark: useColorModeValue(false, true),
        }}
      />
    </Stack>
  );
}
