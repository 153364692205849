import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { useParams } from "react-router-dom";
import moment from "moment";
import { __String } from "typescript";
import ExpenseDetailsScreen from "./ExpenseDetails";
import useCustomTranslation from "../hooks/useCustomTranslation";

const AccountStatement = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<[]>([]);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>("");

  const { t, changeLanguage } = useCustomTranslation();

  const { accountId, accountName } = useParams<{
    accountId: string;
    accountName: string;
  }>();

  useEffect(() => {
    setIsLoading(true);
    getAccountStatement();
  }, []);

  const getAccountStatement = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.post<[]>(
        `${process.env.REACT_APP_TEST_API_URL}/accounts/getStatement`,
        { accountId: accountId },
        { headers }
      );
      console.log(response.data);
      setAccounts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav/>
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <Heading>{accountName}</Heading>
              <TableContainer>
                <Table variant="striped">
                  <TableCaption>{t("Account Statement")}</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>{t("Date")}</Th>
                      <Th>{t("Ref.")}</Th>
                      <Th>{t("Note")}</Th>
                      <Th isNumeric>{t("Amount In")}</Th>
                      <Th isNumeric>{t("Amount Out")}</Th>
                      <Th isNumeric>{t("Balance")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {accounts.length > 0 ? (
                      accounts.map((account: any, index: number) => (
                        <Tr
                          onClick={() => {
                            if (
                              account.transactionType === 2 ||
                              account.transactionType === 4
                            ) {
                              setShowPreviewModal(true);
                              setOrderId(account.transactionNumber.toString());
                            }
                          }}
                        >
                          <Td>{account.id} </Td>
                          <Td>{moment(account.date).format("DD MMM YYYY")}</Td>
                          <Td>{account.transactionId}</Td>
                          <Td>
                            {account.customerName
                              ? account.customerName
                              : account.supplierName
                              ? account.supplierName
                              : account.notes
                              ? account.notes
                              : "Purchase Order - " + account.transactionNumber}
                          </Td>
                          <Td isNumeric>
                            {account.transactionType === 1 ||
                            account.transactionType === 3
                              ? numberWithCommas(account.amount)
                              : null}
                          </Td>
                          <Td isNumeric>
                            {account.transactionType === 2 ||
                            account.transactionType === 4
                              ? numberWithCommas(account.amount)
                              : null}
                          </Td>
                          <Td isNumeric>
                            {numberWithCommas(account.runningTotal)}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        h={400}
                      >
                        <Text fontSize={20} textAlign={"center"}>
                          {t("There are no transactions for this account")}
                        </Text>
                      </Stack>
                    )}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>ID</Th>
                      <Th>{t("Date")}</Th>
                      <Th>{t("Ref.")}</Th>
                      <Th>{t("Note")}</Th>
                      <Th isNumeric>{t("Amount In")}</Th>
                      <Th isNumeric>{t("Amount Out")}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Stack>
      </Stack>
      {/* PREVIEW MODAL */}
      <Modal
        size="xl"
        isCentered
        closeOnOverlayClick={false}
        isOpen={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t("Preview")}</ModalHeader>
          <ModalBody>
            <Stack>
              <ExpenseDetailsScreen ID={orderId} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={() => {
                  setShowPreviewModal(false);
                }}
              >
                {t("Close")}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default AccountStatement;
