import { useEffect, useState } from "react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import moment from "moment";
import Cookies from "js-cookie";

const Logins = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [logins, setLogins] = useState<[]>([]);
  const [selectedLogin, setSelectedLogin] = useState<number>(0);
  const toast = useToast();
  const deviceIdString = Cookies.get("deviceId");
  const loggedInDeviceId = deviceIdString ? parseInt(deviceIdString) : 0;

  useEffect(() => {
    setIsLoading(true);
    getAllLogins();
  }, []);

  const getAllLogins = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<[]>(
        `${process.env.REACT_APP_TEST_API_URL}/auth/showLogins`,
        { headers }
      );
      console.log(response.data);
      setLogins(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const deleteSelectedLogin = async (id: number) => {
    setIsLoading(true);
    if (id === loggedInDeviceId) {
      toast({
        title: "You cannot logout from the current device from here",
        status: "error",
      });
      setIsLoading(false);
      setShowConfirmationModal(false);
      return;
    }
    const headers = await getHeadersForRequest();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/auth/deleteLogin`,
        { id: id },
        { headers }
      );
      console.log(response.data);

      if (response.data.errorCode === 0) {
        setIsLoading(false);
        toast({ title: "Successfully Logged Out", status: "success" });
        setShowConfirmationModal(false);
        await getAllLogins();
      } else {
        toast({ title: "Failed to logout", status: "error" });
        setIsLoading(false);
        setShowConfirmationModal(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav />
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <Heading>Current Logins : {logins.length}</Heading>

              {logins.length > 0 ? (
                <TableContainer>
                  <Table variant={"striped"}>
                    <TableCaption>List of logged in devices</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Device ID</Th>
                        <Th>Date Logged In</Th>
                        <Th>Country</Th>
                        <Th>Device</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {logins.map((data: any, index: number) => (
                        <Tr
                          onClick={() => {
                            setShowConfirmationModal(true);
                            setSelectedLogin(data.deviceId);
                          }}
                        >
                          <Td
                            color={
                              data.deviceId === loggedInDeviceId
                                ? "red"
                                : "unset"
                            }
                          >
                            {data.deviceId === loggedInDeviceId
                              ? "*Current Device"
                              : data.deviceId}
                          </Td>
                          <Td
                            color={
                              data.deviceId === loggedInDeviceId
                                ? "red"
                                : "unset"
                            }
                          >
                            {moment(data.dateGenerated).format("DD MMM YYYY")}
                          </Td>
                          <Td
                            color={
                              data.deviceId === loggedInDeviceId
                                ? "red"
                                : "unset"
                            }
                          >
                            {data.country}
                          </Td>
                          <Td
                            color={
                              data.deviceId === loggedInDeviceId
                                ? "red"
                                : "unset"
                            }
                          >
                            {data.device === "Win32"
                              ? "PC - Browser"
                              : data.device === "Linux armv81"
                              ? "Android Browser"
                              : data.device}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>Device ID</Th>
                        <Th>Date Logged In</Th>
                        <Th>Country</Th>
                        <Th>Device</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              ) : (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    There is no data
                  </Text>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent maxWidth="350">
          <ModalCloseButton />
          <ModalHeader>Logout?</ModalHeader>
          <ModalBody>
            Are you sure you want to logout from the selected Device?
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={() => deleteSelectedLogin(selectedLogin)}
              >
                Logout
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Logins;
